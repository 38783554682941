import * as React from 'react';
import { Grid } from '@mui/material';
import { NavBar } from '../components/NavBar';
import { TitlePage } from '../components/TitlePage';
import SelectedListItem from '../components/Configuracoes/SelectedListItem';
import { Demissao } from '../components/Configuracoes/viewsConfiguracoes/Demissao';
import { EscalaDeTrabalho } from '../components/Configuracoes/viewsConfiguracoes/EscalaDeTrabalho';
import { Insalubridade } from '../components/Configuracoes/viewsConfiguracoes/Insalubridade';
import { Profissao } from '../components/Configuracoes/viewsConfiguracoes/Profissao';

import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import WorkIcon from '@mui/icons-material/Work';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Endereco } from '../components/Configuracoes/viewsConfiguracoes/Endereco';
import { Usuarios } from '../components/Configuracoes/viewsConfiguracoes/Usuarios';
import { Capa } from '../components/Configuracoes/viewsConfiguracoes/Capa';
import { EnderecoAtendimento } from '../components/Configuracoes/viewsConfiguracoes/EnderecoAtendimento';
import 'react-toastify/dist/ReactToastify.css';
import { GrupoPermissoes } from '../components/Configuracoes/viewsConfiguracoes/GrupoPermissoes';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import { EnderecoFilial } from '../components/Configuracoes/viewsConfiguracoes/EnderecoFilial';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import { StatusPeticao } from '../components/Configuracoes/viewsConfiguracoes/StatusPeticao';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import { Notificacoes } from '../components/Configuracoes/viewsConfiguracoes/Notificacoes';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { Formularios } from '../components/Configuracoes/viewsConfiguracoes/Formularios';

var configuracoesLinks = [
    {id: 1, nome: "Demissão", icon: <RuleFolderIcon />},
    {id: 2, nome: "Endereço", icon: <AddLocationAltIcon />},
    {id: 8, nome: "Endereço de atendimento", icon: <ShareLocationIcon />},
    {id: 9, nome: "Endereço filial", icon: <WhereToVoteIcon />},
    {id: 3, nome: "Escala de trabalho", icon: <MoreTimeIcon />},
    {id: 4, nome: "Insalubridade", icon: <MonitorHeartIcon />},
    {id: 5, nome: "Grupos de permissões", icon: <PrivacyTipIcon />},
    {id: 6, nome: "Profissão", icon: <WorkIcon />},
    {id: 7, nome: "Usuários", icon: <GroupAddIcon />},
    {id: 10, nome: "Status da petição", icon: <DisplaySettingsIcon />},
    {id: 11, nome: "Notificações", icon: <EditNotificationsIcon />},
    {id: 12, nome: "Formulários", icon: <BackupTableIcon />},
]

export const Configuracoes = () => {
  const [selectedConfiguracao, setSelectedConfiguracao] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedConfiguracao(index);
  };

  return (
    <Grid container spacing={1}>

      <Grid item lg={12}>
        <NavBar />
      </Grid>

      <Grid item lg={12}>
      <TitlePage title="Configurações" />
      </Grid>

      <Grid item lg={12} >
      <Grid container spacing={1} p={2}>

      
      <Grid item lg={3} sx={{ minHeight: 450, borderRight: 1, borderColor: "#D8D8D8" }}>
      <SelectedListItem configuracoesLinks={configuracoesLinks} handleListItemClick={handleListItemClick} selectedConfiguracao={selectedConfiguracao}/>
      </Grid>

      <Grid item lg={9}>
        {selectedConfiguracao === 0 && <Capa />}
        {selectedConfiguracao === 1 && <Demissao />}
        {selectedConfiguracao === 2 && <Endereco />}
        {selectedConfiguracao === 3 && <EscalaDeTrabalho />}
        {selectedConfiguracao === 4 && <Insalubridade />}
        {selectedConfiguracao === 5 && <GrupoPermissoes />}
        {selectedConfiguracao === 6 && <Profissao />}
        {selectedConfiguracao === 7 && <Usuarios />}
        {selectedConfiguracao === 8 && <EnderecoAtendimento />}
        {selectedConfiguracao === 9 && <EnderecoFilial />}
        {selectedConfiguracao === 10 && <StatusPeticao />}
        {selectedConfiguracao === 11 && <Notificacoes />}
        {selectedConfiguracao === 12 && <Formularios />}
      </Grid>

      </Grid>
      </Grid>
    </Grid>
  );
}
