import Swal from 'sweetalert2';
import usePlataforma from './usePlataforma';
import { useState } from 'react';
import * as Yup from 'yup';
import api from '../service/api';
import { useNavigate } from 'react-router-dom';

const useFormulario = () => {
    const { addCliente, addEnderecoCliente, verificarIsLocal, addEnderecoEmpresa, addEmpresa, verificarCoberturaAtendimento } = usePlataforma()
    const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate();
    const saveEndereceAndReclamante = async (formik, handleNext) => {

        let clienteEncontrado = formik.values.clienteEncontrado
        let enderecoEncontrado = formik.values.enderecoEncontrado

        setIsLoading(true);
        formik.setFieldTouched("cpfReclamante", true, true)
        formik.setFieldTouched("cepReclamante", true, true)

        try {
            var idEnderecoReclamante = formik.values.idEnderecoReclamante

            if (!enderecoEncontrado) {
                const responseEndereco = await addEnderecoCliente(formik.values);
                idEnderecoReclamante = responseEndereco.id
                formik.setFieldValue('idEnderecoReclamante', responseEndereco.id);
                formik.setFieldValue('enderecoEncontrado', true);
            }

            if (!idEnderecoReclamante) {
                Swal.fire({
                    icon: 'error',
                    title: 'Atenção',
                    text: 'Tente preencher as informações corretamente.',
                });
            } else {

                if (!clienteEncontrado) {
                    const responseCliente = await addCliente(formik.values, idEnderecoReclamante);
                    formik.setFieldValue('clienteEncontrado', true);
                }

                const isLocal = await verificarIsLocal(formik.values.cepReclamante)
                formik.setFieldValue('isLocal', isLocal);
                if (isLocal) {
                    formik.setFieldValue('steps', ['Dados do reclamante', 'Dados da reclamada', 'Resumo']);
                }

                handleNext(prevStep => prevStep + 1)
            }

        } catch (error) {
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: 'Tente preencher as informações corretamente.',
            });
        } finally {
            setIsLoading(false);
        }
    }

    const saveEndereceAndReclamada = async (formik, handleNext) => {
        const cnpjEncontrado = formik.values.cnpjEncontrado
        const enderecoCNPJEncontrado = formik.values.enderecoCNPJEncontrado
        let enderecoEncontradoLocalTrabalho = formik.values.enderecoEncontradoLocalTrabalho
        const enderecoIsLocalTrabalho = formik.values.enderecoIsLocalTrabalho

        setIsLoading(true);
        formik.setFieldTouched("cnpjReclamada", true)
        formik.setFieldTouched("cepReclamada", true)

        try {
            var idEnderecoReclamada = formik.values.idEnderecoReclamada
            if (!enderecoCNPJEncontrado) {
                const responseEndereco = await addEnderecoEmpresa(formik.values);
                idEnderecoReclamada = responseEndereco.id
                formik.setFieldValue('idEnderecoReclamada', responseEndereco.id);
                formik.setFieldValue('enderecoCNPJEncontrado', true);
                if (enderecoIsLocalTrabalho) {
                    formik.setFieldValue('idEnderecoLocalTrabalho', responseEndereco.id);
                }
            }

            if (!cnpjEncontrado) {
                const responseEmpresa = await addEmpresa(formik.values, idEnderecoReclamada);
                if (enderecoIsLocalTrabalho) {
                    formik.setFieldValue('idEnderecoLocalTrabalho', idEnderecoReclamada);
                }
                formik.setFieldValue('idEmpresaReclamada', responseEmpresa.id);
                formik.setFieldValue('cnpjEncontrado', true);
            } else if (enderecoIsLocalTrabalho) {
                formik.setFieldValue('idEnderecoLocalTrabalho', idEnderecoReclamada);
            }

            if (enderecoIsLocalTrabalho) {
                var diponibilidade = await verificarCoberturaAtendimento(formik.values.cepReclamada)
                if (diponibilidade) {
                    handleNext(prevStep => prevStep + 1);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Atenção',
                        text: 'Desculpe, mas não atendemos a esse endereço.',
                    });
                }
            } else {
                var diponibilidade = await verificarCoberturaAtendimento(formik.values.cepEnderecoLocalTrabalho)
                if (diponibilidade) {
                    handleNext(prevStep => prevStep + 1);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Atenção',
                        text: 'Desculpe, mas não atendemos a esse endereço.',
                    });
                }
            }
        } catch (error) {
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: 'Tente preencher as informações corretamente.',
            });
        } finally {
            setIsLoading(false);
        }
    }

    function verificarErro(errosObj, tocados, campo) {
        return tocados[campo] && errosObj[campo];
    }

    function verificarErroDoPasso(erros, tocados, camposObrigatorios) {
        return camposObrigatorios.some(campo => verificarErro(erros, tocados, campo));
    }

    function getCamposObrigatorios(data) {
        const camposObrigatorios = {};

        data.forEach((grupo, grupoIndex) => {
            const camposGrupo = grupo.campo_formulario
                .filter(campo => campo.obrigatorio)
                .map(item => item.nome_id);

            const errorKey = `error${grupoIndex}`;
            camposObrigatorios[errorKey] = camposGrupo;
        });

        return camposObrigatorios;
    }

    function getAllCampos(data) {
        const allCampos = {};

        data.forEach(grupo => {
            grupo.campo_formulario.forEach(campo => {
                const nameId = campo.nome_id;
                const isCheckbox = campo.campo.tipo_campo.tipo === "checkbox"
                if (nameId === "enderecoIsLocalTrabalho") {
                    allCampos[nameId] = isCheckbox ? true : "";
                } else {
                    allCampos[nameId] = isCheckbox ? false : "";
                }
            });
        });

        return allCampos;
    }

    function getvalidationSchema(data) {
        const allCampos = {};

        data.forEach(grupo => {
            grupo.campo_formulario.forEach(campo => {
                if (campo.obrigatorio) {
                    const nameId = campo.nome_id;
                    allCampos[nameId] = Yup.string().required('Este campo é obrigatório.');
                }

            });
        });

        return allCampos;
    }

    function getIsLocal(formik, data) {
        return formik.values.isLocal ? data.filter(item => item.nome === 'DADOS_RECLAMANTE' || item.nome === 'DADOS_RECLAMANDA' || item.nome === 'RESUMO') : data.filter(item => item.nome !== 'RESUMO');
    }

    function getSteps(isLocal) {
        return isLocal.map(campos => campos.campo_formulario.length > 0 ? campos.nome_label : null).filter(campos => campos !== null)
    }

    async function sendForm(form_id, data, setCarregando) {
        try {
            setCarregando(true)
            await api.post("/api/formularios/resposta", { form_id, data })
            Swal.fire({
                icon: 'success',
                title: 'Atenção',
                text: 'Formulário enviado com Sucesso!',
            });
            setCarregando(false)
            navigate("/")
        } catch (error) {
            setCarregando(false)
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.response?.data?.message || "Ocorreu um erro. Tente novamente mais tarde.",
            });
        }

    }

    return {
        isLoading,
        saveEndereceAndReclamante,
        saveEndereceAndReclamada,
        getCamposObrigatorios,
        getAllCampos,
        getvalidationSchema,
        verificarErroDoPasso,
        getIsLocal,
        getSteps,
        sendForm
    };
}

export default useFormulario;
