import * as React from "react";
import {
    Chip,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
} from "@mui/material";
import LabelIcon from "@mui/icons-material/Label";
import ModeIcon from "@mui/icons-material/Mode";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

export const ListEditNotificacao = ({ borderBottom, currentItem, creatDataNotificao }) => {
    const { type, mensagem, action } = currentItem

    function handleEditar() {
        creatDataNotificao({ mensagem, isNewNotificacao: Boolean(mensagem) })
    }

    function handleCriar() {
        creatDataNotificao({ currentType: type, currentAction: action })
    }

    return (
        <React.Fragment>
            <ListItem
                sx={{ borderBottom, borderColor: "divider" }}
            >
                <ListItemIcon>
                    <LabelIcon />
                </ListItemIcon>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ width: "100%" }}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <ListItemText primary={type} />
                        <Chip label={action} size="small" />
                    </Stack>
                    {mensagem ? (
                        <Tooltip title="Editar notificação">
                            <IconButton onClick={handleEditar} aria-label="Editar notificação">
                                <ModeIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Criar notificação">
                            <IconButton onClick={handleCriar} aria-label="Criar notificação">
                                <LibraryAddIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            </ListItem>
        </React.Fragment>
    );
};
