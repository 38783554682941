import React from "react";
import { Box, Grid, LinearProgress } from "@mui/material";
import { NavBar } from "../components/NavBar";
import { useParams } from "react-router-dom";

import { LoadingForm } from "../components/FormularioDinamico/components/LoadingForm";
import { ErrorForm } from "../components/FormularioDinamico/components/ErrorForm";
import { ViewForm } from "../components/FormularioDinamico/components/ViewForm";
import { OpcoesViewPeticao } from "../components/OpcoesViewPeticao";
import useFetchFormularioResposta from "../queries/getFormularioResposta";
import useFetchGetConfigFormulariosID from "../queries/configFormularios[id]";
import { decryptJSON } from "../utils/jsonCrypto";

export const PeticaoView = () => {
    const { id } = useParams();
    const decrypt = decryptJSON(id)

    const { isError, isLoading, data } = useFetchFormularioResposta(decrypt.idResposta)
    const { isError: configIsError, isLoading: configIsLoading, data: configData } = useFetchGetConfigFormulariosID(data && data.form_id)

    return (
        <Box>
            {isLoading && <LinearProgress />}
            <NavBar />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ mt: 3, px: 4 }}
                spacing={3}
            >
                {data && (
                    <Grid item xs={12}>
                        <OpcoesViewPeticao idPeticao={decrypt.idPeticao} idResposta={decrypt.idResposta} viewData={decrypt} />
                    </Grid>
                )}

                {isLoading || configIsLoading ? (
                    <LoadingForm />
                ) : isError ? (
                    <ErrorForm />
                ) : data && data.form_id ? (
                    <ViewForm setFieldValues={data.respostas} data={configData} disabled={true} />
                ) : (
                    <LoadingForm />
                )}
            </Grid>

        </Box>
    );
}
