import { Box, Button, Grid, LinearProgress, Skeleton, Stack, Typography } from "@mui/material"
import { useParams } from "react-router-dom";
import { NavBar } from "../components/NavBar";
import AccordionUsage from "../components/FormularioEditar/AccordionUsage";
import QueueIcon from '@mui/icons-material/Queue';
import useFetchGetConfigFormulariosID from "../queries/configFormularios[id]";
import CustomizedDialogs from "../components/FormularioEditar/CustomizedDialogs";
import useFetchGetConfigTipoCampo from "../queries/configTipoCampo";
import { useState } from "react";
import GrupoDialogs from "../components/FormularioEditar/GrupoDialogs";
import FormularioDialogs from "../components/FormularioEditar/FormularioDialogs";
import TipoCampoDialogs from "../components/FormularioEditar/TipoCampoDialogs";

import { ViewForm } from "../components/FormularioDinamico/components/ViewForm";
import { ErrorForm } from "../components/FormularioDinamico/components/ErrorForm";
import { FormEmpty } from "../components/FormularioDinamico/components/FormEmpty";
import { LoadingForm } from "../components/FormularioDinamico/components/LoadingForm";
import { TiposCampos } from "../components/FormularioDinamico/components/TiposCampos";

export const FormularioEditar = () => {
    const { id } = useParams();
    const [dialogSettings, setDialogSettings] = useState(null);
    const [grupoDialogSettings, setGrupoDialogSettings] = useState(null);
    const [formularioDialogSettings, setFormularioDialogSettings] = useState(null);
    const [tipoCampoDialogSettings, setTipoCampoDialogSettings] = useState(null);

    const { isError, isLoading, data } = useFetchGetConfigFormulariosID(id)
    const { data: tipoCampo } = useFetchGetConfigTipoCampo()
    const formEmpty = data?.map(grupo => grupo.campo_formulario.length !== 0).filter(item => item !== false).length === 0

    const openEditName = (descricao) => {
        setFormularioDialogSettings({ editar: true, formulario: { id, descricao } })
    }

    return (
        <Box >
            {isLoading && <LinearProgress />}
            <NavBar />
            <Grid p={4} container spacing={2}>
                <Grid item xs={3}>
                    {isLoading ? <Skeleton variant="rounded" sx={{ height: "80vh", borderRadius: '12px' }} /> :
                        <Box sx={{ minHeight: "80vh", border: 1, borderColor: "#D8D8D8", borderRadius: '12px' }}>

                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                px={2} my={2}
                            >
                                <Typography variant="h6">Grupos</Typography>
                                <Button
                                    startIcon={<QueueIcon />}
                                    sx={{ p: 0, m: 0, px: 1, fontWeight: 500, fontSize: 12 }}
                                    size="small"
                                    variant="contained"
                                    onClick={() => setGrupoDialogSettings({ editar: false })}
                                >
                                    Novo
                                </Button>
                            </Stack>

                            <AccordionUsage setGrupoDialogSettings={setGrupoDialogSettings} setDialogSettings={setDialogSettings} grupos={data} />

                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                px={2} my={2}
                            >
                                <Typography variant="h6">Tipos de campos</Typography>
                                <Button onClick={() => setTipoCampoDialogSettings({ editar: false })} startIcon={<QueueIcon />} sx={{ p: 0, m: 0, px: 1, fontWeight: 500, fontSize: 12 }} size="small" variant="contained">Novo</Button>
                            </Stack>

                            <TiposCampos tiposCampo={tipoCampo && tipoCampo.data} />
                        </Box>
                    }
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{ minHeight: "80vh", border: 1, borderColor: "#D8D8D8", borderRadius: '12px' }}>
                        {isLoading ? <LoadingForm /> : isError ? <ErrorForm /> : formEmpty ? <FormEmpty /> : <ViewForm setDialogSettings={setDialogSettings} data={data} disabled={false} openEditName={openEditName} />}
                    </Box>
                </Grid>
            </Grid>
            {dialogSettings && <CustomizedDialogs tipoCampo={tipoCampo} dialogSettings={{ ...dialogSettings, formulario_id: id }} setDialogSettings={setDialogSettings} />}
            {grupoDialogSettings && <GrupoDialogs dialogSettings={grupoDialogSettings} setDialogSettings={setGrupoDialogSettings} />}
            {formularioDialogSettings && <FormularioDialogs dialogSettings={formularioDialogSettings} setDialogSettings={setFormularioDialogSettings} />}
            {tipoCampoDialogSettings && <TipoCampoDialogs dialogSettings={tipoCampoDialogSettings} setDialogSettings={setTipoCampoDialogSettings} />}
        </Box>
    )
}
